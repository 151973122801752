<template>
  <section class="section">
    <div class="container">
      <h2 class="title">Get in Touch</h2>
      <form @submit.prevent="submitForm">
        <!-- Name Field -->
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" type="text" v-model="formData.name" required />
          </div>
        </div>

        <!-- Email Field -->
        <div class="field">
          <label class="label">Email</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="email" v-model="formData.email" placeholder="youremail@example.com" required />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
        </div>

        <!-- Phone Field -->
        <div class="field">
          <label class="label">Phone</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="tel" v-model="formData.phone" placeholder="8888888888" required />
            <span class="icon is-small is-left">
              <i class="fas fa-phone"></i>
            </span>
          </div>
        </div>

        <!-- Message Field -->
        <div class="field">
          <label class="label">Your Message</label>
          <div class="control">
            <textarea class="textarea" v-model="formData.message" required></textarea>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="field">
          <div class="control">
            <button class="button is-primary" type="submit">Send Message</button>
          </div>
        </div>
      </form>

      <!-- Success Message -->
      <div v-if="isSubmitted" class="notification is-success">
        <button class="delete" @click="clearSuccessMessage"></button>
        <p>Message successfully sent! We'll be in touch soon.</p>
      </div>

      <!-- Error Message -->
      <div v-if="isError" class="notification is-danger">
        <button class="delete" @click="clearErrorMessage"></button>
        <p>There was an issue sending your message. Please try again later.</p>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isSubmitted: false,
      isError: false,
    };
  },
  methods: {
    // Submit the form to EmailJS
    submitForm() {
      const templateParams = {
        from_name: this.formData.name,
        from_email: this.formData.email,
        phone: this.formData.phone,
        message: this.formData.message,
      };

      emailjs
        .send(
          "service_nvdd65d", 
          "template_fqdihzl", // Replace with your EmailJS template ID
          templateParams,
         "Ma-s_nnSD1NmNYYXu"
        )
        .then(
          (response) => {
            console.log('Message sent successfully:', response);
            this.isSubmitted = true;
            this.isError = false;
            this.formData = { name: '', email: '', phone: '', message: '' }; // Clear form after submission
          },
          (error) => {
            console.error('Failed to send message:', error);
            this.isError = true;
            this.isSubmitted = false;
          }
        );
    },

    // Clear success message
    clearSuccessMessage() {
      this.isSubmitted = false;
    },

    // Clear error message
    clearErrorMessage() {
      this.isError = false;
    },
  },
};
</script>

<style scoped>
/* Form styles */
.field {
  margin-bottom: 1.5rem;
}

.input, .textarea {
  width: 100%;
  padding: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.button.is-primary {
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
}

.notification.is-success {
  background-color: #28a745;
  color: white;
  border-radius: 8px;
}

.notification.is-danger {
  background-color: #dc3545;
  color: white;
  border-radius: 8px;
}

.notification .delete {
  color: white;
}
</style>
