<template>
  <div>
    <!-- Navbar (Removed menu and Get in Touch) -->
    <nav class="navbar is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" @click="scrollToSection('hero')">
          <img src="@/assets/logo.png" alt="Logo">
        </a>
      </div>
    </nav>

    <!-- Hero Section -->
    <section id="hero" class="hero is-fullheight-with-navbar has-background-light">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-6 has-text-left">
              <h1 class="title is-2 has-text-dark">
                Transforming Ideas into Reality with AI, Design, and Development
              </h1>
              <p class="subtitle is-4 has-text-dark">
                Innovate faster with cutting-edge solutions tailored to your vision.
              </p>
            </div>
            <div class="column is-6">
              <div class="box has-background-white p-6 shadow-md">
                <h2 class="title is-4 has-text-centered">Contact Us</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section id="services" class="section has-background-grey-lighter">
      <div class="container">
        <h2 class="title has-text-centered">Our Services</h2>
        <div class="columns is-multiline is-centered">
          <div class="column is-3" v-for="service in services" :key="service.title">
            <div class="card service-card is-hoverable">
              <div class="card-content has-text-centered">
                <div class="icon-container">
                  <span class="icon is-large">
                    <i :class="service.icon"></i>
                  </span>
                </div>
                <p class="title">{{ service.title }}</p>
                <p class="subtitle">{{ service.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Us Section -->
    <section id="about" class="section has-background-white">
      <div class="container">
        <h2 class="title has-text-centered">About Us</h2>
        <p class="content has-text-centered">
          Catalyst Labs pioneers innovation in AI and software, shaping the future through technology.
        </p>
      </div>
    </section>

    <!-- Footer with Copyright -->
    <footer class="footer has-background-dark">
      <div class="content has-text-centered has-text-white">
        <p>&copy; 2025 Catalyst Labs. All rights reserved. Innovating the future, today.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import "animate.css";

export default {
  components: {
    ContactForm, // Keep only ContactForm in components
  },
  data() {
    return {
      services: [
        { title: "Design", description: "Crafting visually stunning and user-centric experiences.", icon: "fas fa-paint-brush" },
        { title: "Development", description: "Building high-performance and scalable software.", icon: "fas fa-code" },
        { title: "Consulting", description: "Strategic insights to optimize your business solutions.", icon: "fas fa-lightbulb" },
        { title: "AI Discovery & Development", description: "Transforming businesses through AI-powered innovation.", icon: "fas fa-robot" }
      ]
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
/* Main Background */
body {
  background-color: #f0f0f0; /* Light grey background for modern look */
  color: #333; /* Dark text for readability */
  font-family: 'Arial', sans-serif;
}

/* Navbar Styling */
.navbar {
  background: #fff; /* White navbar for a clean, modern look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
}

/* Hero Section */
.hero-body .columns {
  align-items: center;
  margin-bottom: 4rem;
}

.hero-body .container {
  padding: 2rem;
}

.title.is-2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.subtitle.is-4 {
  font-size: 1.25rem;
  font-weight: 400;
}

.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.shadow-md {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Services Section */
.service-card {
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 100%; /* Allow the cards to stretch to the full height */
  min-height: 300px; /* Ensure cards have a minimum height */
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.icon-container {
  background: linear-gradient(135deg, #007bff, #6c63ff);
  padding: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.icon-container .icon {
  font-size: 2.5rem;
  color: white;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  color: #555;
  font-weight: 400;
}

/* Footer Styling */
footer {
  padding: 2rem 0;
  background-color: #333;
  color: #fff;
}

footer p {
  font-size: 1rem;
  font-weight: 300;
}

/* Spacing and Responsiveness */
.columns.is-centered {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .column.is-3 {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 480px) {
  .column.is-3 {
    flex: 0 0 100%;
  }
}

/* Prevent text overlapping */
.title, .subtitle {
  margin-bottom: 1rem;
}

/* Ensure uniform card sizes */
.columns.is-multiline {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem; /* Space between cards */
}

.column.is-3 {
  flex: 0 0 23%; /* Ensure cards take 23% of the width */
}

@media screen and (max-width: 768px) {
  .column.is-3 {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 480px) {
  .column.is-3 {
    flex: 0 0 100%;
  }
}

</style>
